// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Ahmed",
  middleName: "",
  lastName: "Massoud",
  message: " Passionate about building maintainable software. ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/ahmedwalid05",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/ahmedwalid05/",
    },
  ],
};

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/ahmedmassoud.jpeg"),
  imageSize: 375,
  message1: "Software engineer based in Berlin, Germany 🇩🇪",
  resume: require("../editable-stuff/resume.pdf"),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "ahmedwalid05", //i.e."johnDoe12Gh"
  reposLength: 0,
  specificRepos: [
    "FastExcel",
    "DiscordChatExporter",
    "freelancer-autonotify",
    "instagram-private-api",
  ],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
};

// SKILLS SECTION
const skills = {
  show: false,
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "If you have any questions, or if you just want to say hi, please feel free to email me at",
  email: "me@ahmedmassoud.me",
};

const experiences = {
  show: true,
  heading: "Experiences",
  data: [{
    role: "Software Development Engineer I",
    date: "February 2023 - Present",
    companylogo: require("../assets/img/aws.png"),
    height: '100px'

  },
  {
    role: "Software Engineer",
    companylogo: require("../assets/img/nova-blue.png"),
    date: "September 2021 – October 2022",
    height: '50px'

  },
  {
    role: "Software Engineer",
    companylogo: require("../assets/img/quickbyte.png"),
    date: "December 2020 – August 2021",
  },
  {
    role: "Software Engineering Intern", // Here Add Company Name
    companylogo: require("../assets/img/agi.png"),
    date: "July 2020 – August 2020",
  },
  ],
};

export {
  navBar,
  mainBody,
  about,
  repos,
  skills,
  leadership,
  getInTouch,
  experiences,
};
