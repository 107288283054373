import axios from 'axios'
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';


export const api = axios.create({});

setupCache(api, { 
    // As localStorage is a public storage, you can add a prefix
    // to all keys to avoid collisions with other code.
    storage: buildWebStorage(localStorage, 'axios-cache:'),
    cacheTakeover: false,
    interpretHeader: false
  });
  
  